/* eslint-disable */
// prettier-ignore

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
        {
      path: '/projects',
      name: 'projects',
      component: () => import('@/views/apps/projects/projects-list/ProjectsList.vue'),
            meta: {"pageTitle":"\u041f\u0440\u043e\u0435\u043a\u0442\u044b"},
          },
        {
      path: '/projects/:id/view',
      name: 'project-view',
      component: () => import('@/views/apps/projects/project-view/ProjectView.vue'),
            meta: {"pageTitle":"\u0414\u0435\u0442\u0430\u043b\u044c\u043d\u0430\u044f \u0441\u0440\u0430\u043d\u0438\u0446\u0430","breadcrumb":[{"text":"\u041f\u0440\u043e\u0435\u043a\u0442\u044b","active":false,"to":"\/projects"}]},
          },
        {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/apps/reports/reports-list/ReportsList.vue'),
            meta: {"pageTitle":"\u041e\u0442\u0447\u0435\u0442\u044b"},
          },
        {
      path: '/payments',
      name: 'payments',
      component: () => import('@/views/apps/payments/payments-list/PaymentsList.vue'),
            meta: {"pageTitle":"\u041f\u043b\u0430\u0442\u0435\u0436\u0438"},
          },
        {
      path: '/docs',
      name: 'docs',
      component: () => import('@/views/apps/documents/documents-list/DocumentsList.vue'),
            meta: {"pageTitle":"\u0414\u043e\u043a\u0443\u043c\u0435\u043d\u0442\u044b"},
          },
        {
      path: '/',
      name: 'users',
      component: () => import('@/views/apps/users/users-list/UsersList.vue'),
            meta: {"pageTitle":"\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438"},
          },
        {
      path: '/users/:id/view',
      name: 'user-view',
      component: () => import('@/views/apps/users/user-view/UserView.vue'),
            meta: {"pageTitle":"\u0414\u0435\u0442\u0430\u043b\u044c\u043d\u0430\u044f \u0441\u0440\u0430\u043d\u0438\u0446\u0430","breadcrumb":[{"text":"\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438","active":false,"to":"\/"}]},
          },
        {
      path: '/users/:id/edit',
      name: 'user-edit',
      component: () => import('@/views/apps/users/user-edit/UserEdit.vue'),
            meta: {"pageTitle":"\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f","breadcrumb":[{"text":"\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438","active":false,"to":"\/"}]},
          },
        {
      path: '/report-form/:id',
      name: 'report-form',
      component: () => import('@/views/apps/reports/report-form/ReportForm.vue'),
            meta: {"pageTitle":"\u041e\u0442\u0447\u0435\u0442\u044b","layout":"full"},
          },
        {
      path: '/report-form/:id/:channel',
      name: 'report-form',
      component: () => import('@/views/apps/reports/report-form/ReportForm.vue'),
            meta: {"pageTitle":"\u041e\u0442\u0447\u0435\u0442\u044b","layout":"full"},
          },
        {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/apps/profile/ProfileSetting.vue'),
            meta: {"pageTitle":"\u041f\u0440\u043e\u0444\u0438\u043b\u044c"},
          },
        {
      path: '/(00\\w+)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-login',
      name: 'verify-login',
      component: () => import('@/views/pages/VerifyLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-login/:id',
      name: 'verify-login',
      component: () => import('@/views/pages/VerifyLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/pages/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/confidence',
      name: 'confidence',
      component: () => import('@/views/pages/Confidence.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})



router.beforeEach(async (to, from, next) => {
  if (!store.getters['app/isLoggedIn'] && localStorage.getItem('accessToken') && localStorage.getItem('userData')) {
    store.commit('app/SET_USER', JSON.parse(localStorage.getItem('userData')))
    store.commit('app/SET_LOGGED_IN', true)
  }

  if (!localStorage.getItem('accessToken') && to.matched[0] && to.matched[0].path !== '/login') {
    if (to.matched[0].path === '/signup') {
      next()
    } else if (to.matched[0].path === '/verify-login/:id') {
      next()
    } else if (to.matched[0].path === '/verify-login') {
      next()
    } else if (to.matched[0].path === '/(00\\w+)') {
      next()
    } else if (to.matched[0].path === '/reset-password') {
      next()
    } else if (to.matched[0].path === '/confidence') {
      next()
    } else if (to.matched[0].path === '/forgot-password') {
      next()
    } else if (to.matched[0].path === '/report-form/:id') {
      next()
    } else if (to.matched[0].path === '/report-form/:id/:channel') {
      next()
    } else {
      next({
        path: '/login',
      })
    }
  }

  if (localStorage.getItem('accessToken') && to.matched[0] && to.matched[0].path === '/login') {
    next({
      path: '/',
    })
  }

  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
